import React from 'react';
import { Link, useStaticQuery, graphql } from 'gatsby';

import Img from 'gatsby-image';
import AnchorLink from 'react-anchor-link-smooth-scroll';

import Layout from '../components/layout/Layout';
import Card from '../components/Card';
import CalloutSimple from '../components/CalloutSimple';
import Seo from '../components/Seo';

import tick from '../assets/icons/tick.png'



export default () => {
  const title = 'Plans and Pricing | Strio ';
  const description =
    "Discover Strio's plans and find the one that best suits your need. Try Strio free for 14 days.";
  const url = 'https://strio.app/pricing';

  return (
    <Layout>
      <Seo title={title} description={description} url={url} />

      <div className="bg-lownight h-16 md:h-20" />
      <section className="relative pa-0 overflow-hidden">
        <div className="squared-background-left" />
        <div className="squared-background-right" />
        
          <div className="container py-2 px-4">
            <div className="text-center md:text-left text-lownight">
              <h1 className="mb-10 mt-6 lg:mt-10 xl:mt-18 text-2xl lg:text-3xl xl:text-4xl font-bold leading-none tracking-tight text-center">
                Strio Pricing
              </h1>
            </div>
          </div>
      </section>

      <section id="features" className="bg-white">
        <div className="container text-center w-full lg:w-4/5 xl:w-3/5">
          <div className="flex flex-col sm:flex-row sm:mx-10">
            <div className="flex-1 p-1 mx-4">
              <Card>
                <p className="font-semibold text-3xl font-display text-lownight">
                  Team
                </p>
                  <ul className="text-base font-display text-gray-700 text-left flex-1">
                    <li className="flex my-2">
                      <img src={tick} alt="tick icon" className="hidden md:block h-6 mr-4" />
                      <p>
                        Up to 5 concurrent environments
                      </p>
                    </li>
                    <hr/>
                    <li className="my-2 flex">
                      <img src={tick} alt="tick icon" className="hidden md:block h-6 mr-4" />
                      <p>
                      Up to 5 services
                      </p>
                    </li>
                    <hr/>
                    <li className="my-2 flex">
                      <img src={tick} alt="tick icon" className="hidden md:block h-6 mr-4" />
                      <p>
                      Up to 1,000 minutes usage per month
                      </p>
                    </li>
                    <hr/>
                  </ul>
                <Link
                  className="text-base w-full inline-flex items-center justify-center px-8 py-3 mt-8 border border-transparent leading-6 font-bold tracking-wide rounded-md text-white bg-codestream hover:bg-cloud focus:outline-none focus:shadow-outline transition duration-300 ease-in-out"
                  to="/contact"
                >
                  Get in touch!
                </Link>
                <p className="text-gray-700 text-l mt-4">
                    14-day free trial
                </p>
              </Card>
            </div>




            <div className="flex-1 p-1 mx-4">
              <Card>
                <p className="font-semibold text-3xl font-display text-lownight">
                  Entreprise
                </p>
                <ul className="text-base font-display text-gray-700 text-left">
                  <li className="my-2 flex">
                    <img src={tick} alt="tick icon" className="hidden md:block h-6 mr-4" />
                    <p>
                    Unlimited concurrent environments
                    </p>
                  </li>
                  <hr/>
                  <li className="my-2 flex">
                    <img src={tick} alt="tick icon" className="hidden md:block h-6 mr-4" />
                    <p>
                     Unlimited services
                    </p>
                  </li>
                  <hr/>
                  <li className="my-2 flex">
                    <img src={tick} alt="tick icon" className="hidden md:block h-6 mr-4" />
                    <p>
                    Cost monitoring
                    </p>
                  </li>
                  <hr/>
                  <li className="my-2 flex">
                    <img src={tick} alt="tick icon" className="hidden md:block h-6 mr-4" />
                    <p>
                    Role-based access control
                    </p>
                  </li>
                  <hr/>
                  <li className="my-2 flex">
                    <img src={tick} alt="tick icon" className="hidden md:block h-6 mr-4" />
                    <p>
                     24/7 support
                    </p>
                  </li>
                  <hr/>
                  <li className="mt-2 flex">
                    <img src={tick} alt="tick icon" className="hidden md:block h-6 mr-4" />
                    <p>
                    Custom integrations services
                    </p>
                  </li>
                </ul>
                <Link
                  className="text-base w-full inline-flex items-center justify-center px-8 py-3 mt-8 border border-transparent leading-6 font-bold tracking-wide rounded-md text-white bg-codestream hover:bg-cloud focus:outline-none focus:shadow-outline transition duration-300 ease-in-out"
                  to="/contact"
                >
                  Get in touch!
                </Link>
                <p className="text-gray-700 text-l mt-4">
                    14-day free trial
                </p>
              </Card>
            </div>
          </div>
        </div>
        <div className="mt-6 mb-12">
          <p className="text-center text-lownight text-xl my-4">
            Do you have an open-source project running on Kubernetes? <Link className="text-blue-500 hover:text-blue-800" to="/contact">Contact us</Link> to start using Strio for free!
          </p>
        </div>
      </section>
    

    </Layout>
  );
};
